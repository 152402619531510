<template>
  <div class="index my-booking">
    <!-- <van-nav-bar :title="'预定详情'" /> -->
  <van-skeleton title :row="5" :loading="loading" >
    <div class="booking-detail-card">
      <h3>{{getStatus(booking)}}</h3>
      <div class="content">
        <div class="ho-center left-right">
          <div class="restaurant-info ho-center">
            <van-image :src="booking.restaurantImageUrl" />
            <!-- <img src="../assets/banner1.png" alt=""> -->
            <span>{{booking.restaurantName}}</span>
            <!-- <van-icon color="#999" name="arrow" @click="onRestaurant(booking.restaurantId)" /> -->
          </div>
          <div>
            <!-- <van-icon color="#999" name="arrow" @click="onRestaurant(booking.restaurantId)" /> -->
          </div>
          
        </div>
        <van-cell title="预定" :value="booking.personName" />
        <van-cell title="预定电话" :value="booking.telphone" />
        <van-cell title="预定人数" :value="booking.personNumber > 8 ? '8+人' : booking.personNumber + '人'" />
        <van-cell title="预定日期" :value="getBookingTime(booking)" />
        <van-cell title="过敏源" :value="booking.allergy" />
        <van-cell title="备注" :value="booking.remark" />
      </div>
      <hr>
      <div class="float-right ho-center" >
        <van-button v-if="isDoCanceling(booking)"  plain size="mini" type="" @click="onCancel(booking.id)">取消预定</van-button>
      </div>
    </div>
  </van-skeleton>

    <van-overlay :show="canceling" >
      <div class="cancel-loading-spinner">
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter , useRoute } from "vue-router";
import { Toast } from 'vant';
import { parseTime } from "../utils/index"
import { userLoginTimeOutInit } from "../api/user-timeout";
import BookingService from "../api/restaurant-booking-service";
import User from "../api/user";
import "../css/booking-details.scss";
import "../css/common.scss"

export default {
  components: {},
  setup() {
    const state = reactive({
      booking: {},
      thirdparty: false,
      bookingId: null,
      orderId: null,
      // canceling: false,
      loading: false
    });
    const router = useRouter();
    const {id, orderId} = useRoute().params;
    state.thirdparty = orderId ? true : false;
    state.bookingId = id;
    state.orderId = orderId;
    const onRestaurant = (id) => router.push("/restaurant/" + id);
    const onCancel = (id) => {
        Toast.loading({
          duration: 0,
          overlay: true,
          loadingType: 'spinner',
        })
        // state.canceling  = true;
        BookingService.cancelRestaurantBooking(id)
          .then(getBookingDetails)
          .then( booking => {
              // state.canceling  = false;
              state.booking = booking;
              Toast.clear();
              Toast.success("成功取消预约");
          }, ()=> {
              // state.canceling  = false;
              Toast.clear();
              Toast.fail("取消预约失败，请联系相关客服。");
          })
    };
    const getBookingDetails = ()=> state.thirdparty 
        ? BookingService.getRestaurantBookingDetailsByThirdPartyOrderId(state.orderId)
        : BookingService.getRestaurantBookingDetails(state.bookingId);

    const getStatus = booking => {
      if( booking.status == 'USER_BOOKING') {
        return "申请预约"
      } else if( booking.status == 'USER_CANCEL') {
        return "已取消预约"
      } else if( booking.status == 'RESTAURANT_CONFIRM') {
        return "已确定预约"
      } else if( booking.status == 'RESTAURANT_REJECT') {
        return "已拒绝预约"
      } else if( booking.status == 'RESTAURANT_CANCEL') {
        return "已取消预约"
      } else {
        return "";
      }
    }
    const isDoCanceling = (booking) => booking.status == 'USER_BOOKING' ||  booking.status == 'RESTAURANT_CONFIRM';
    const getBookingTime = booking =>  parseTime(new Date(booking.bookingTime), "{y}-{m}-{d} {h}:{i}");
    onMounted(()=> {
      const { code, client, jumpUrl} = useRoute().query;
      const data = { code, client, jumpUrl};
      state.loading = true;
      User.login(data)
        .then(() =>  getBookingDetails())
      // getBookingDetails()
        .then(booking => {
            state.booking = booking;
            state.loading = false;
            userLoginTimeOutInit(data);
        },() => {
            router.push("/404");
            state.loading = false;
        });
    });
    return {
      ...toRefs(state),
      onCancel,
      isDoCanceling,
      onRestaurant,
      getStatus,
      getBookingTime
    };
  },
};
</script>
