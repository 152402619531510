import { Dialog } from 'vant';
import { goEcHome , initEcScript } from "../utils/ec"
import User from "./user";
const LOCAL_USER_EXPIRED_KEY = "ec_user_expired";
const LOCAL_USER_CODE_KEY = "ec_user_code";

function createExpiredTime(code) {
    let value = sessionStorage.getItem(LOCAL_USER_CODE_KEY);
    if (value && code == value) {
        return;
    }
    let expired = new Date().getTime() + 30 * 60 * 1000;
    sessionStorage.setItem(LOCAL_USER_EXPIRED_KEY, expired);
    sessionStorage.setItem(LOCAL_USER_CODE_KEY, code);
}

function getExpiredTime() {
    let expiredTime = sessionStorage.getItem(LOCAL_USER_EXPIRED_KEY);
    if( !expiredTime) {
        return -1;
    }
    let seconds =  parseInt((expiredTime - new Date().getTime()) /1000);
    return seconds > 0 ? seconds : 0;
}

let userLoginTimeOutRemind = () => {
    return Dialog.alert({ message: '登陆信息已失效，请返回小程序重新预订。' }).then(() => {
        User.logout();
        goEcHome(User.getClient());
    });
};

const refreshExpiredTime = () => {
    let seconds = getExpiredTime();
    if (seconds > 0) {
        createRefreshExpiredTime();
    } else {
        userLoginTimeOutRemind();
    }
}
const createRefreshExpiredTime = () => setTimeout(refreshExpiredTime, 1000)

export function userLoginTimeOutInit({ code }) {
    initEcScript(User.getClient())
    createExpiredTime(code)
    createRefreshExpiredTime();
}
